/* global $ */

import React from "react";
import projects from "../../projects.json";

const ProjectCard = () => {
  const sortedProjects = [...projects.projects].sort((a, b) => a.id - b.id);

  return (
    <>
      {sortedProjects.map((project) => (
        <div className="col-lg-4 col-md-4 col-sm-6 grid-item" key={project.id}>
          <div className="featured-imagebox featured-imagebox-portfolio style1">
            <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
              <div className="featured-thumbnail">
                <a href={project.link} className="ttm_link">
                  <img className="img-fluid" src={project.cover} alt="" />
                </a>
              </div>
              <div className="ttm-media-link">
                <a href={project.link} className="ttm_link">
                  <i className="ti ti-plus" />
                </a>
              </div>
            </div>
            <div className="featured-content featured-content-portfolio">
              <div className="featured-title">
                <h3>
                  <a href={project.link}>{project.title}</a>
                </h3>
              </div>
              <span className="category">
                <span>
                  <i>{project.location}</i>
                </span>
                <br />
                <span>{project.ended}</span>
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectCard;
