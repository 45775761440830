import React from "react";

function Error() {
  return (
    <div className="site-main .error">
      <section className="error-404 ttm-bgimage-yes error-404 ttm-bg ttm-bgcolor-darkgrey">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="ttm-big-icon ttm-textcolor-skincolor">
            <i className="fa fa-thumbs-o-down" />
          </div>
          <header className="page-header">
            <h1 className="page-title">404 ERROR</h1>
          </header>
          <div className="page-content">
            <p>
              This page may have been moved or deleted. Be sure to check your
              spelling.
            </p>
          </div>
          <a
            className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white"
            href="/"
          >
            Back to Home <i className="fa fa-long-arrow-right" />
          </a>
        </div>
      </section>
    </div>
  );
}

export default Error;
