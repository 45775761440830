import React from "react";
import ProjectCard from "../../components/projectCard/ProjectCard";
import EnviromentalPDF from "../../assets/Project PDF/BRW Project Enviromental.pdf";
import CommercialPDF from "../../assets/Project PDF/BRW Project Commercial.pdf";
import HealthcarePDF from "../../assets/Project PDF/BRW Project Healthcare.pdf";
import IndustrialPDF from "../../assets/Project PDF/BRW Project Industrial.pdf";
import MultiFamilyPDF from "../../assets/Project PDF/BRW Project Multi Family.pdf";
import MunicipalityPDF from "../../assets/Project PDF/BRW Project Municipality.pdf";
import RoadwayPDF from "../../assets/Project PDF/BRW Project Roadway.pdf";
import SingleFamilyPDF from "../../assets/Project PDF/BRW Project Single Family.pdf";
import EducationalPDF from "../../assets/Project PDF/BRW Project Educational.pdf";

const Projects = () => {
  const openPDFInNewWindow = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">Our Projects</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/">
                      <i className="fa fa-house" /> &nbsp; Home
                    </a>
                  </span>
                  <span>Projects</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <section className="ttm-row portfolio-section clearfix">
          <div className="container">
            <div className="row" style={{ marginBottom: 50 }}>
              <div className="col-lg-12">
                <div className="ttm-tabs ttm-tab-style-03">
                  <div className="d-lg-flex align-items-center justify-content-between">
                    <div className="ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-grey ttm-bg ttm-left-span res-991-padding_top50 res-991-padding_right30 res-991-padding_bottom30 res-991-mt_40 res-991-mr_20">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-1">
                        <div className="ttm-col-wrapper-bg-layer-inner" />
                      </div>
                      <div className="layer-content">
                        <div className="section-title margin_bottom45">
                          <div className="title-header">
                            <h3>BRW PROJECTS</h3>
                            <h2 className="title">
                              Several of Our Featured <span>Projects</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content-tab">
                    <div
                      className="row isotope-project"
                      style={{ position: "relative" }}
                    >
                      <ProjectCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="section-title title-style-center_text">
                <div className="other-title-header">
                  <h3>CLICK BELOW FOR ADDITIONAL PROJECTS</h3>
                  <div>
                    <button
                      onClick={() => openPDFInNewWindow(CommercialPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      COMMERCIAL
                      <br />
                      <i className="fa-solid fa-store"></i>
                    </button>
                    <button
                      onClick={() => openPDFInNewWindow(MultiFamilyPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      MULTI-FAMILY
                      <br />
                      <i className="fa-solid fa-building"></i>
                    </button>
                    <button
                      onClick={() => openPDFInNewWindow(RoadwayPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      ROADWAY
                      <br />
                      <i className="fa-solid fa-road"></i>
                    </button>
                    <button
                      onClick={() => openPDFInNewWindow(IndustrialPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      INDUSTRIAL
                      <br />
                      <i className="fa-solid fa-industry"></i>
                      <br />
                    </button>
                    <button
                      onClick={() => openPDFInNewWindow(HealthcarePDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      HEALTHCARE
                      <br />
                      <i className="fa-solid fa-heart-pulse"></i>
                    </button>
                    <button
                      onClick={() => openPDFInNewWindow(SingleFamilyPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      SINGLE-FAMILY
                      <br />
                      <i className="fa-solid fa-house"></i>
                    </button>
                    <button
                      onClick={() => openPDFInNewWindow(MunicipalityPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      MUNICIPALITY
                      <br />
                      <i className="fa-solid fa-tree-city"></i>
                    </button>

                    <button
                      onClick={() => openPDFInNewWindow(EducationalPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      EDUCATIONAL
                      <br />
                      <i className="fa-solid fa-school"></i>
                    </button>

                    <button
                      onClick={() => openPDFInNewWindow(EnviromentalPDF)}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-style-fill ttm-btn-color-darkgrey"
                    >
                      ENVIRONMENTAL
                      <br />
                      <i className="fa-solid fa-leaf"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Projects;
